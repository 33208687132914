<template>
  <YoTable
    :api-endpoint="apiEndpoint"
    :title-tbl="this.$t('COMPANY OWN')"
    :sub-title-tbl="this.$t('Delivery Routes')"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label=" this.$t('KeteranganTable') + '~'+ this.$t('Form Land Vehicle')+'~'"
    bottom-icon="GitMergeIcon"
    placeholder-search="Route"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable,
  },
  data() {
    return {
      apiEndpoint: 'delivery_route',
      fields: [
        {
          searchModel: 'group.name',
          search: {
            type: 'text',
          },
          minWidth: '130px',
          key: 'group.name',
          label: 'GROUP',
          placeholder: '',
          sortable: true,
          input: { type: 'input-noarea', disabled: true },
        },
        {
          searchModel: 'last_route',
          search: {
            type: 'text',
          },
          key: 'last_route',
          label: 'Tujuan',
          placeholder: 'Tujuan',
          sortable: true,
          input: { type: 'input-noarea' },
        },
        {
          searchModel: 'user.full_name',
          search: {
            type: 'text',
          },
          key: 'user.full_name',
          label: 'Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
        },
        {
          searchModel: 'user.phone',
          search: {
            type: 'number',
          },
          key: 'user.phone',
          label: 'Phone of Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
        },
        {
          searchModel: 'created_at',
          search: {
            type: 'date',
          },
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true,
          },
        },
        {
          searchModel: 'updated_at',
          search: {
            type: 'date',
          },
          key: 'updated_at',
          label: 'Updated AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true,
          },
        },
      ],
    }
  },
  computed: {
    newDataTemplate() {
      return {
        last_route: '',
      }
    },
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.delivery_routes
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC',
        },
      }
    },
  },
}
</script>
